.page-full-height {
  height: calc(100vh - 165px);
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgb(199, 190, 190);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(89, 89, 89, 0.69);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.desktop-menu, .mobile-menu {
  .active {
    color: black;
    font-weight: bold;
  }
}

.modal-parent {
  .full-height {
    min-height: 100vh;
  }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  .modal-btn:checked + label,
  .modal-btn:not(:checked) + label {
    transition: all 200ms linear;
  }

  .modal-btn:checked + label .uil,
  .modal-btn:not(:checked) + label .uil {
    margin-left: 10px;
    font-size: 18px;
  }

  .modal-btn:checked + label:after,
  .modal-btn:not(:checked) + label:after {
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 110;
    width: 30px;
    border-radius: 50%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    background-color: #ee1332;
    color: #ffffff;
    font-family: 'unicons';
    content: '';
    box-shadow: 0 12px 25px 0 rgba(16, 39, 112, .25);
    transition: all 200ms linear;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
  }

  .modal-btn:checked + label:hover:after,
  .modal-btn:not(:checked) + label:hover:after {
    //background-color: #102770;
    //color: #ffeba7;
  }

  .modal-btn:checked + label:after {
    transition: opacity 300ms 300ms ease, transform 300ms 300ms ease, background-color 250ms linear, color 250ms linear;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .modal {
    position: fixed;
    display: block;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow-x: hidden;
    background-color: rgba(31, 32, 41, .75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
  }

  .modal-btn:checked ~ .modal {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
  }

  .modal-wrap {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 4px;
    overflow: hidden;
    //padding-bottom: 20px;
    background-color: #fff;
    -ms-flex-item-align: center;
    align-self: center;
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, .25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  }

  .modal-wrap img {
    display: block;
    width: 100%;
    height: auto;
  }

  .modal-wrap p {
    padding: 20px 30px 0 30px;
  }

  .modal-btn:checked ~ .modal .modal-wrap {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
  }

  .modal-btn:checked ~ .logo img {
    filter: brightness(100%);
    transition: all 250ms linear;
  }


  @media screen and (max-width: 500px) {
    .modal-wrap {
      width: calc(100% - 40px);
      padding-bottom: 15px;
    }
    .modal-wrap p {
      padding: 15px 20px 0 20px;
    }
  }
}

.h-full-i {
  height: 100% !important;
}
.h-500px{
  height: 500px !important;
}
.search-model {
  max-height: calc(100vh - 300px);
  padding-bottom: 10px;
  left: 25% !important;
  .content{
    max-height: calc(100vh - 395px);
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px){
    //max-height: calc(100vh - 100px);
    padding-bottom: 10px;
    left: 8% !important;
    top: 10% !important;
    .content{
      max-height: calc(100vh - 395px);
      margin-bottom: 10px;
    }
  }
}
.link-preview {
  .Description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.project-cat-active {
  color: #000000;
  font-weight: bold;
}

.project-card {
  overflow: hidden;

  img {
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }

  &:hover img {
    transform: scale(1.2);
  }
}

.project-card-title {
  background-color: rgba(0, 0, 0, 0.5);
}

.single-project-thumb {
  opacity: .5;
  border: 1px solid #cccccc;
}

.active-thumb {
  border: 1px solid #eeeeee;
}

.webkit-inline-box {
  display: inline-flex;
}

.project-video-dialog {
  max-width: 80% !important;
  max-height: 85% !important;
  height: 85% !important;
  @media screen and (max-width: 700px) {
    max-width: 100% !important;
    max-height: 50% !important
  }
  div{
    height: 100% !important;
    div{
      height: 100% !important;
    }
  }
  button {
    display: none;
    z-index: 10;
    background-color: #fd7150;
    border-radius: 50%;
    top: 5px;
    right: 5px;

    svg {
      fill: white;
      padding: 3px;
    }
  }
  &:hover button {
    display: block;
  }
}

.project-single-gallery-height {
  height: calc(100vh - 221px);
  @media screen and (max-width: 700px) {
    height: auto;
  }

}

.project-info-dialog {
  padding: 40px;
  max-width: max-content !important;
  max-height: 90% !important;
  display: inline-flex !important;
  div{
    &::-webkit-scrollbar {
      display: none;
    }
  }


  button {
    z-index: 10;
    background-color: #fd7150;
    border-radius: 50%;
    top: 5px;
    right: 5px;

    svg {
      fill: white;
      padding: 3px;
    }
  }
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid rgba(89, 89, 89, 0.69);
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid rgba(89, 89, 89, 0.69);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.about-dialog{

  button {
    z-index: 10;
    background-color: #fd7150;
    border-radius: 50%;
    //top: 5px;
    //right: 5px;

    svg {
      fill: white;
      padding: 3px;
    }
  }
}

.single-project{
  .image-slider{
    .prev-button, .next-button{
      transform: scale(0);

      transition: all 200ms ease-in-out;
      i{
        color: #b0afaf;
        @media screen and (max-width: 700px) {
          background: rgba(9, 9, 9, 0.31);
          color: white;
        }
      }
      @media screen and (max-width: 700px) {
        transform: scale(1);
      }
    }
    &:hover{
      .prev-button, .next-button{
        transform: scale(1);

      }
    }
  }
}
.m-project-select-box{

  option{
    &:checked{
      background-color: rgba(224, 224, 224, 0.69) !important;
    }
  }
  option[selected] {
    background-color: rgba(224, 224, 224, 0.69) !important;
  }
  option::selection{
    background-color: rgba(224, 224, 224, 0.69) !important;
  }
}

.hide-nextButton{
  .splide__arrow--next{
    display: none;
  }
}
.hide-prevButton{
  .splide__arrow--prev{
    display: none;
  }
}
.hidden-button{
  display: none !important;
}